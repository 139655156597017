import React from 'react';
import ZoomInIcon from '@material-ui/icons/ZoomIn';
import ZoomOutIcon from '@material-ui/icons/ZoomOut';
import Image from '../image/index';
import { IdDetails, OCRDetails } from './interface';
import './idDetailsComparison.css';

type Props = {
  cropAppIdImage: string;
  cropDbIdImage: string;
  idDetails: IdDetails;
  ocrDetails: OCRDetails;
  zoom: boolean;
  zoomIdDetailsHandler: () => void;
};

const IdDetailsComparison = ({
  cropAppIdImage,
  cropDbIdImage,
  idDetails,
  ocrDetails,
  zoom,
  zoomIdDetailsHandler,
}: Props) => {
  let nidDetailsMatch;
  let nameDetailsMatch;
  let dobDetailsMatch;
  // let ocrNidMatch;
  // let ocrNameMatch;
  // let ocrDobMatch;

  if (idDetails.nidMatch === 'Match')
    nidDetailsMatch = <span className="IdDetailsComparison-Green">Match</span>;
  else if (idDetails.nidMatch === 'Mismatch')
    nidDetailsMatch = <span className="IdDetailsComparison-Red">Mismatch</span>;
  else nidDetailsMatch = <span>NA</span>;

  if (idDetails.nameMatch !== undefined) {
    if (idDetails.nameMatch)
      nameDetailsMatch = (
        <span className="IdDetailsComparison-Green">Match</span>
      );
    else
      nameDetailsMatch = (
        <span className="IdDetailsComparison-Red">Mismatch</span>
      );
  } else nameDetailsMatch = null;

  if (idDetails.dobMatch !== undefined) {
    if (idDetails.dobMatch)
      dobDetailsMatch = (
        <span className="IdDetailsComparison-Green">Match</span>
      );
    else
      dobDetailsMatch = (
        <span className="IdDetailsComparison-Red">Mismatch</span>
      );
  } else dobDetailsMatch = null;

  // if (ocrDetails.nidMatch === 'yes')
  //   ocrNidMatch = <span className="IdDetailsComparison-Green">Match</span>;
  // else if (ocrDetails.nidMatch === 'no')
  //   ocrNidMatch = <span className="IdDetailsComparison-Red">Mismatch</span>;
  // else ocrNidMatch = <span>NA</span>;

  // if (ocrDetails.nameMatch === 'yes')
  //   ocrNameMatch = <span className="IdDetailsComparison-Green">Match</span>;
  // else if (ocrDetails.nameMatch === 'no')
  //   ocrNameMatch = <span className="IdDetailsComparison-Red">Mismatch</span>;
  // else ocrNameMatch = <span>NA</span>;

  // if (ocrDetails.dobMatch === 'yes')
  //   ocrDobMatch = <span className="IdDetailsComparison-Green">Match</span>;
  // else if (ocrDetails.dobMatch === 'no')
  //   ocrDobMatch = <span className="IdDetailsComparison-Red">Mismatch</span>;
  // else ocrDobMatch = <span>NA</span>;

  let queryOCRNidMatch;
  let queryOCRNameMatch;
  let queryOCRDobMatch;
  let queryInputNidMatch;
  let queryInputNameMatch;
  let queryInputDobMatch;

  if (idDetails.appNid) {
    if (idDetails.appNid === ocrDetails.ocrNid) {
      queryInputNidMatch = undefined;
      queryOCRNidMatch = 'IdDetailsComparison-SubTitle-Bold';
    } else if (idDetails.appNid === ocrDetails.inputNid) {
      queryInputNidMatch = 'IdDetailsComparison-SubTitle-Bold';
      queryOCRNidMatch = undefined;
    }
  } else {
    queryOCRNidMatch = 'IdDetailsComparison-SubTitle-Bold';
  }

  if (idDetails.appName) {
    if (idDetails.appName === ocrDetails.ocrName) {
      queryOCRNameMatch = 'IdDetailsComparison-SubTitle-Bold';
      queryInputNameMatch = undefined;
    } else if (idDetails.appName === ocrDetails.inputName) {
      queryOCRNameMatch = undefined;
      queryInputNameMatch = 'IdDetailsComparison-SubTitle-Bold';
    }
  } else {
    queryOCRNameMatch = 'IdDetailsComparison-SubTitle-Bold';
  }

  if (idDetails.appDob) {
    if (idDetails.appDob === ocrDetails.ocrDob) {
      queryOCRDobMatch = 'IdDetailsComparison-SubTitle-Bold';
      queryInputDobMatch = undefined;
    } else if (idDetails.appDob === ocrDetails.inputDob) {
      queryOCRDobMatch = undefined;
      queryInputDobMatch = 'IdDetailsComparison-SubTitle-Bold';
    }
  } else {
    queryOCRDobMatch = 'IdDetailsComparison-SubTitle-Bold';
  }

  const ocrNidConfValue =
    typeof ocrDetails.ocrNidConf === 'number'
      ? ocrDetails.ocrNidConf.toFixed(2)
      : ocrDetails.ocrNidConf;

  const ocrNameConfValue =
    typeof ocrDetails.ocrNameConf === 'number'
      ? ocrDetails.ocrNameConf.toFixed(2)
      : ocrDetails.ocrNameConf;

  const ocrDobConfValue =
    typeof ocrDetails.ocrDobConf === 'number'
      ? ocrDetails.ocrDobConf.toFixed(2)
      : ocrDetails.ocrDobConf;

  const detailsQC = (
    <>
      <h5 className="IdDetailsComparison-Bold">Details</h5>
      <table>
        <tbody>
          <tr>
            <td className="IdDetailsComparison-SubTitle-Bold">Id Number</td>
            <td>
              {' : '}
              {nidDetailsMatch}
            </td>
          </tr>

          <tr className={queryOCRNidMatch}>
            <td>Query OCR</td>
            <td>
              : {ocrDetails.ocrNid} (Conf : {ocrNidConfValue})
            </td>
          </tr>
          <tr className={queryInputNidMatch}>
            <td>Query User Input</td>
            <td>: {ocrDetails.inputNid}</td>
          </tr>

          <tr>
            <td>Response</td>
            <td>: {idDetails.dbNid}</td>
          </tr>
        </tbody>
      </table>
      <table>
        <tbody>
          <tr>
            <td className="IdDetailsComparison-SubTitle-Bold">Name</td>
            <td>{nameDetailsMatch}</td>
          </tr>

          <tr className={queryOCRNameMatch}>
            <td>Query OCR </td>
            <td>
              : {ocrDetails.ocrName} (Conf : {ocrNameConfValue})
            </td>
          </tr>
          <tr className={queryInputNameMatch}>
            <td>Query User Input</td>
            <td>: {ocrDetails.inputName}</td>
          </tr>

          <tr>
            <td>Response</td>
            <td>: {idDetails.dbName}</td>
          </tr>
        </tbody>
      </table>
      <table>
        <tbody>
          <tr>
            <td className="IdDetailsComparison-SubTitle-Bold">DOB</td>
            <td>{dobDetailsMatch}</td>
          </tr>

          <tr className={queryOCRDobMatch}>
            <td>Query OCR</td>
            <td>
              : {ocrDetails.ocrDob} (Conf : {ocrDobConfValue})
            </td>
          </tr>
          <tr className={queryInputDobMatch}>
            <td>Query User Input</td>
            <td>: {ocrDetails.inputDob}</td>
          </tr>

          <tr>
            <td>Response</td>
            <td>: {idDetails.dbDob}</td>
          </tr>
        </tbody>
      </table>
    </>
  );

  const appImageQC = (
    <div className="IdDetailsComparison-AppImage">
      <Image
        src={cropAppIdImage}
        height={zoom ? 400 : 320}
        alt="crop application image"
      />
      <p className="IdDetailsComparison-Tag">Query Image</p>
    </div>
  );

  const dbImageQC = (
    <div className="IdDetailsComparison-DBImage">
      <Image
        src={cropDbIdImage}
        height={zoom ? 400 : 320}
        alt="crop DB image"
      />
      <p className="IdDetailsComparison-Tag">Response Image</p>
    </div>
  );

  return (
    <div className="IdDetailsComparison-Container">
      <div className="IdDetailsComparison-Title-Holder">
        <p className="IdDetailsComparison-Title">Id Details Comparison</p>
        <div className="IdDetailsComparison-Icon">
          {zoom ? (
            <ZoomOutIcon onClick={() => zoomIdDetailsHandler()} />
          ) : (
            <ZoomInIcon onClick={() => zoomIdDetailsHandler()} />
          )}
        </div>
      </div>

      <div className="IdDetailsComparison-Flex-Container">
        {appImageQC}
        {dbImageQC}
        <div className="IdDetailsComparison-Details">{detailsQC}</div>
      </div>
    </div>
  );
};

export default IdDetailsComparison;
