import React from 'react';
import './spinner.css';

const Spinner = () => (
  <div className="Loader">
    <h3>Loading...</h3>
  </div>
);

export default Spinner;
