/* eslint-disable no-param-reassign, import/no-cycle */
// This project is setup using redux-toolkit which includes immer by default.
// Hence state is mutable.

import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import axios from 'axios';
import { AppThunk, RootState } from '../../store';
import loginState from './initialState';
import LoginState from './interface';

const initialState: LoginState = {
  ...loginState,
};

export const loginSlice = createSlice({
  name: 'login',
  initialState,
  reducers: {
    setAppId: (state, action: PayloadAction<string>) => {
      state.appId = action.payload;
    },
    setAppKey: (state, action: PayloadAction<string>) => {
      state.appKey = action.payload;
    },
    setAuthState: (state, action: PayloadAction<boolean>) => {
      state.isAuthenticated = action.payload;
    },
    setAccessToken: (state, action: PayloadAction<string>) => {
      localStorage.setItem('accessToken', action.payload);
      state.accessToken = action.payload;
    },
    setError: (state, action: PayloadAction<boolean>) => {
      state.error = action.payload;
    },
    setErrorMessage: (state, action: PayloadAction<string>) => {
      state.errorMessage = action.payload;
    },
    setLoading: (state, action: PayloadAction<boolean>) => {
      state.loading = action.payload;
    },
    setCheckMade: (state, action: PayloadAction<boolean>) => {
      state.checkMade = action.payload;
    },
    setRole: (state, action: PayloadAction<string>) => {
      state.role = action.payload;
    },
  },
});

export const {
  setAppId,
  setAppKey,
  setAuthState,
  setAccessToken,
  setError,
  setErrorMessage,
  setLoading,
  setCheckMade,
  setRole,
} = loginSlice.actions;

// The function below is called a thunk and allows us to perform async logic. It
// can be dispatched like a regular action: `dispatch(incrementAsync(10))`. This
// will call the thunk with the `dispatch` function as the first argument. Async
// code can then be executed and other actions can be dispatched

// HV Auth
// 400: {
//   "status": "failure",
//   "statusCode": "401",
//   "error": "Missing/Invalid credentials"
// }
// Success: {
//   "status": "success",
//   "statusCode": "200",
//   "result": {
//       "token": "Bearer eyJhbGciOiJSUzI1NiIsInR5cCI6IkpXVCJ9.eyJhcHBJZCI6ImRlbW9IViIsImhhc2giOiI5OTQxMzk1ODA3M2U2ZGY1ODQwZGYwNDUxZDUyZDU0Zjg0MTQxMTJjYjcxOGU3YmZhMzVlYzRlNzg2YzlhYjYxIiwiaWF0IjoxNjE3ODcxMTc4LCJleHAiOjE2MTc4NzExNzgsImp0aSI6Ijc3MjdjZDIxLWZjNzEtNGE2Zi05NjFmLTM3YWE5MmJjYTQwNCJ9.k0k6-I8MDn2XcBXjJVbEK-eIaI6Z6-bWwyG5k8H7JFs-8QeNgDtlzUDadsJLQWuPk9qqzKJ1o681y2v6qpYZEwpYxZM-_6y5VTnAjTD8w4afBWS44vkKVXoFd5ARBDYI0BjZlyoKzdX7nUI_UD-EmPGvnaCxA6_AKg3Z9RSbhXA"
//   }
// }
export const getAccessToken = (
  appId: string,
  appKey: string
): AppThunk => async (dispatch) => {
  try {
    dispatch(setLoading(true));
    const authResult: any = await axios.post(
      `${process.env.REACT_APP_BACKEND_URL}/v1/login`,
      { appId, appKey, expiry: 3600 * 24 }
    );
    // console.log(appId, appKey);
    // const authResult = { data: { result: { token: 'token' } } };
    dispatch(setAccessToken(authResult.data));
    dispatch(setAuthState(true));
    dispatch(setLoading(false));
    dispatch(setError(false));
    dispatch(setErrorMessage(''));
  } catch (err) {
    dispatch(setError(true));
    dispatch(setErrorMessage('Unauthorized. Check credentials'));
    dispatch(setLoading(false));
  }
};

export const getAccessTokenValidity = (accessToken: string): AppThunk => async (
  dispatch
) => {
  try {
    if (accessToken.length === 0) {
      dispatch(setAuthState(false));
      dispatch(setCheckMade(true));
    } else {
      const validityResult: any = await axios.get(
        `${process.env.REACT_APP_BACKEND_URL}/v1/validate`,
        {
          headers: {
            authorization: accessToken,
          },
        }
      );
      // const validityResult = { data: { role: 'client' } };
      dispatch(setRole(validityResult.data.role));
      dispatch(setAuthState(true));
      dispatch(setCheckMade(true));
    }
  } catch (err) {
    dispatch(setAuthState(false));
    dispatch(setCheckMade(true));
  }
};

// The function below is called a selector and allows us to select a value from
// the state. Selectors can also be defined inline where they're used instead of
// in the slice file. For example: `useSelector((state: RootState) => state.counter.value)`
export const selectAppId = (state: RootState): string => state.login.appId;
export const selectAppKey = (state: RootState): string => state.login.appKey;
export const selectAccessToken = (state: RootState): string =>
  state.login.accessToken;
export const selectAuthState = (state: RootState): boolean =>
  state.login.isAuthenticated;
export const selectError = (state: RootState): boolean => state.login.error;
export const selectErrorMessage = (state: RootState): string =>
  state.login.errorMessage;
export const selectLoading = (state: RootState): boolean => state.login.loading;
export const selectCheckMade = (state: RootState): boolean =>
  state.login.checkMade;
export const selectRole = (state: RootState): string => state.login.role;

export default loginSlice.reducer;
