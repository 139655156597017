export const isFalsy = (data: any) => {
  if (data === undefined || data === null) return true;
  return false;
};

export const getResultFromBoolean = (data: boolean | undefined | null) => {
  if (isFalsy(data)) return 'NA';
  if (data) return 'Yes';
  return 'No';
};
