import { Input } from '@material-ui/core';
import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import {
  getAccessToken,
  setAppId,
  setAppKey,
  selectAppId,
  selectAppKey,
  selectAuthState,
  selectErrorMessage,
  selectError,
  selectLoading,
  getAccessTokenValidity,
} from './loginSlice';
import './loginPage.css';
import Spinner from '../../components/spinner';

const LoginPage = () => {
  const dispatch = useDispatch();
  const history = useHistory();

  useEffect(() => {
    dispatch(getAccessTokenValidity(localStorage.getItem('accessToken') || ''));
  }, [dispatch]);

  const appKey = useSelector(selectAppKey);
  const appId = useSelector(selectAppId);
  const isAuthenticated = useSelector(selectAuthState);
  const errorMessage = useSelector(selectErrorMessage);
  const error = useSelector(selectError);
  const loading = useSelector(selectLoading);

  if (isAuthenticated) {
    history.push('/home');
  }

  let errorClass = 'LoginPage-Hidden';
  if (error) {
    errorClass = 'LoginPage-Error';
  }

  const onAppIdChangeHandler = (e: any) => {
    dispatch(setAppId(e.target.value));
  };

  const onAppKeyChangeHandler = (e: any) => {
    dispatch(setAppKey(e.target.value));
  };

  const handleSubmit = (e: any) => {
    e.preventDefault();
    dispatch(getAccessToken(appId, appKey));
  };

  const loadingRender = <Spinner />;
  const loginRender = (
    <div className="LoginPage-Container">
      <h2 className="LoginPage-Title">Sign In</h2>
      <br />
      <form onSubmit={handleSubmit}>
        <Input
          className="LoginPage-Input"
          placeholder="AppId"
          inputProps={{ 'aria-label': 'description' }}
          onChange={onAppIdChangeHandler}
          required
        />
        <br />
        <Input
          className="LoginPage-Input"
          placeholder="AppKey"
          inputProps={{ 'aria-label': 'description' }}
          onChange={onAppKeyChangeHandler}
          type="password"
          autoComplete="on"
          required
        />
        <br />
        <br />
        <button className="LoginPage-Button" type="submit">
          Sign In
        </button>
        <br />
        <br />
        <p className={errorClass}>{errorMessage}</p>
      </form>
    </div>
  );

  const render = loading ? loadingRender : loginRender;

  return render;
};

export default LoginPage;
