/* eslint-disable @typescript-eslint/ban-ts-comment */

import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useParams, useLocation, useHistory } from 'react-router-dom';
import { Alert } from '@material-ui/lab';
import {
  selectCurrentHit,
  selectCurrentHitCategory,
  selectCurrentHitIndex,
  selectClientId,
  selectCheck,
  selectCount,
  selectApplicationImages,
  selectInput,
  setCurrentHitCategory,
  getQueryLog,
  setCurrentHitIndex,
  setZoomSelfie,
  setZoomIdFace,
  setZoomIdDetails,
  selectError,
  selectFetchStatus,
  selectZoomIdDetails,
  selectZoomIdFace,
  selectZoomSelfie,
  setError,
  selectNoHit,
} from './clientReviewSlice';
import ApplicationBar from '../../components/applicationBar/index';
import MismatchBar from '../../components/mismatchBar/index';
import SelfieComparison from '../../components/selfieComparison/index';
import IdFaceComparison from '../../components/idFaceComparison/index';
import IdDetailsComparison from '../../components/idDetailsComparison/index';
import Spinner from '../../components/spinner/index';
import './clientReviewPage.css';
import {
  getAccessTokenValidity,
  selectAuthState,
  selectCheckMade,
} from '../loginPage/loginSlice';
import SearchBar from '../../components/searchBar';

const ClientReviewPage = () => {
  const dispatch = useDispatch();
  const history = useHistory();

  useEffect(() => {
    dispatch(getAccessTokenValidity(localStorage.getItem('accessToken') || ''));
  }, [dispatch]);

  const checkMade = useSelector(selectCheckMade);
  const isAuthenticated = useSelector(selectAuthState);
  if (checkMade && !isAuthenticated) {
    dispatch(setError(false));
    history.push('/login');
  }
  const { requestId } = useParams<{ requestId: string }>();

  const useQuery = () => {
    return new URLSearchParams(useLocation().search);
  };
  const query = useQuery();
  const category = query.get('category');
  const hitNumber = query.get('hitNumber');

  useEffect(() => {
    dispatch(getQueryLog(requestId));
  }, [dispatch, requestId]);

  useEffect(() => {
    dispatch(
      setCurrentHitCategory(category === 'matches' ? 'matches' : 'mismatches')
    );
  }, [dispatch, category]);

  useEffect(() => {
    dispatch(setCurrentHitIndex(hitNumber ? Number(hitNumber) - 1 : 0));
  }, [dispatch, hitNumber]);

  const [prev, setPrev] = useState(false);
  const [next, setNext] = useState(false);

  useEffect(() => {
    window.addEventListener('keydown', (e) => {
      if (e.key === 'ArrowLeft') setPrev(true);
      if (e.key === 'ArrowRight') setNext(true);
    });
  }, []);

  const currentHit = useSelector(selectCurrentHit);
  const currentHitCategory = useSelector(selectCurrentHitCategory);
  const currentHitIndex = useSelector(selectCurrentHitIndex);
  const clientId = useSelector(selectClientId);
  const checkData = useSelector(selectCheck);
  const countData = useSelector(selectCount);
  const applicationImages = useSelector(selectApplicationImages);
  const input = useSelector(selectInput);
  const fetchFlag = useSelector(selectFetchStatus);
  const errorStatus = useSelector(selectError);
  const zoomSelfie = useSelector(selectZoomSelfie);
  const zoomIdFace = useSelector(selectZoomIdFace);
  const zoomIdDetails = useSelector(selectZoomIdDetails);
  const noHit = useSelector(selectNoHit);

  const nextHitHandler = (scrollFlag: boolean) => {
    const totalHit = countData.total[currentHitCategory];
    let hitNumberValue;
    if (totalHit) {
      if (currentHitIndex + 1 >= totalHit) {
        hitNumberValue = 0;
      } else {
        hitNumberValue = currentHitIndex + 1;
      }
      hitNumberValue += 1;
    }
    history.push({
      pathname: `/clientreview/${requestId}`,
      search: `?category=${currentHitCategory}&hitNumber=${hitNumberValue}`,
    });
    if (scrollFlag) window.scrollTo(0, 0);
  };

  const previousHitHandler = (scrollFlag: boolean) => {
    const totalHit = countData.total[currentHitCategory];
    let hitNumberValue;
    if (totalHit) {
      if (currentHitIndex - 1 < 0) {
        hitNumberValue = totalHit - 1;
      } else {
        hitNumberValue = currentHitIndex - 1;
      }
      hitNumberValue += 1;
    }
    history.push({
      pathname: `/clientreview/${requestId}`,
      search: `?category=${currentHitCategory}&hitNumber=${hitNumberValue}`,
    });
    if (scrollFlag) window.scrollTo(0, 0);
  };

  const logOutHandler = () => {
    localStorage.setItem('accessToken', '');
    history.push('/login');
  };

  if (prev) {
    setPrev(false);
    previousHitHandler(false);
  }

  if (next) {
    setNext(false);
    nextHitHandler(false);
  }

  const setHitCategoryHandler = (hitCategory: 'mismatches' | 'matches') => {
    history.push({
      pathname: `/clientreview/${requestId}`,
      search: `?category=${hitCategory}&hitNumber=1`,
    });
  };

  const zoomSelfieHandler = () => {
    dispatch(setZoomSelfie());
  };

  const zoomIdFaceHandler = () => {
    dispatch(setZoomIdFace());
  };

  const zoomIdDetailsHandler = () => {
    dispatch(setZoomIdDetails());
  };

  // Make sure to update AI decision based on interface change
  // Make sure to add threshold here. Right now its optional
  const selfieDetails = {
    matchScore: currentHit?.matchScore?.selfie,
    AIDecision: currentHit?.aiDecision?.selfie
      ? currentHit.aiDecision.selfie
      : 'NA',
  };
  // Make sure to update AI decision based on interface change
  // Make sure to add threshold here. Right now its optional
  const faceDetails = {
    matchScore: currentHit?.matchScore?.idFaceCrop,
    AIDecision: currentHit?.aiDecision?.idFace
      ? currentHit.aiDecision.idFace
      : 'NA',
  };
  const inputData = {
    ocrNid: input?.ocr?.id?.value ? input.ocr.id.value : 'NA',
    ocrNidConf: input?.ocr?.id?.conf ? input?.ocr?.id?.conf : 'NA',
    inputNid: input?.user?.idNumber ? input.user.idNumber : 'NA',
    nidMatch: checkData?.matchingAPI?.idNumber,
    ocrName: input?.ocr?.name?.value ? input.ocr.name.value : 'NA',
    ocrNameConf: input?.ocr?.name?.conf ? input?.ocr?.name?.conf : 'NA',
    inputName: input?.user?.name ? input.user.name : 'NA',
    nameMatch: checkData?.matchingAPI?.name,
    ocrDob: input?.ocr?.dob?.value ? input.ocr.dob.value : 'NA',
    ocrDobConf: input?.ocr?.dob?.conf ? input?.ocr?.dob?.conf : 'NA',
    inputDob: input?.user?.dob ? input.user.dob : 'NA',
    dobMatch: checkData?.matchingAPI?.dob,
  };
  const idDetails = {
    appNid: input?.query?.idNumber,
    dbNid: currentHit.nid,
    nidMatch: currentHit?.aiDecision?.idNumber
      ? currentHit.aiDecision.idNumber
      : 'NA',
    appName: input?.query?.name,
    dbName: currentHit.name,
    appDob: input?.query?.dob,
    dbDob: currentHit.dob,
  };

  const clientReviewRender = (
    <div className="ClientReviewPage">
      <SearchBar logOut={logOutHandler} />
      <ApplicationBar
        requestId={requestId}
        clientId={clientId}
        verifyStatus={checkData?.verify?.value}
        enrolment={checkData?.enrol?.value}
        selfieBlur={checkData?.selfie?.quality?.blur?.overall}
        verifyPair={checkData?.verifyPair?.match}
        applicationId={input?.user?.applicationId}
      />
      {noHit ? (
        <Alert severity="info">No match/mismatch available</Alert>
      ) : null}
      <MismatchBar
        decision={currentHit.reason}
        noOfMatch={countData.total.matches}
        noOfMismatch={countData.total.mismatches}
        active={currentHitCategory}
        currentActive={currentHitIndex + 1}
        nextHit={nextHitHandler}
        previousHit={previousHitHandler}
        setHitCategory={setHitCategoryHandler}
      />
      <div className="ClientReviewPage-Flex-Container">
        <div className="ClientReviewPage-Selfie">
          <SelfieComparison
            originalAppImage={applicationImages?.selfieImages?.original}
            originalDbImage={currentHit?.selfieImages?.original}
            cropAppImage={applicationImages?.selfieImages?.fraasCrop}
            selfieDetails={selfieDetails}
            zoom={zoomSelfie}
            clientMode
            zoomSelfieHandler={zoomSelfieHandler}
          />
        </div>
        <div className="ClientReviewPage-IdFace">
          <IdFaceComparison
            originalAppIdImage={applicationImages?.idImages?.original}
            cropFaceAppImage={applicationImages?.idImages?.fraasCrop}
            cropFaceDbImage={currentHit?.idImages?.fraasCrop}
            faceDetails={faceDetails}
            zoom={zoomIdFace}
            clientMode
            zoomIdFaceHandler={zoomIdFaceHandler}
          />
        </div>
      </div>
      <div className="ClientReviewPage-Bottom-Container">
        <IdDetailsComparison
          cropAppIdImage={applicationImages?.idImages?.ssd}
          cropDbIdImage={currentHit?.idImages?.ssd}
          idDetails={idDetails}
          ocrDetails={inputData}
          zoom={zoomIdDetails}
          zoomIdDetailsHandler={zoomIdDetailsHandler}
        />
      </div>
    </div>
  );

  let loadingRender = <Spinner />;
  if (errorStatus)
    loadingRender = (
      <div>
        <Alert severity="error">An Error Occured! Report RequestId</Alert>
      </div>
    );
  const actualRender = fetchFlag ? clientReviewRender : loadingRender;

  return actualRender;
};

export default ClientReviewPage;
