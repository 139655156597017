import React from 'react';
import ZoomInIcon from '@material-ui/icons/ZoomIn';
import ZoomOutIcon from '@material-ui/icons/ZoomOut';
import Image from '../image/index';
import { SelfieDetails, FacePPDetails } from './interface';
import { isFalsy, getResultFromBoolean } from '../../utils/validation';
import './selfieComparison.css';

type Props = {
  originalAppImage: string;
  originalDbImage: string;
  cropAppImage: string;
  cropDbImage?: string;
  selfieDetails: SelfieDetails;
  facePPDetails?: FacePPDetails;
  zoom: boolean;
  debug?: boolean;
  clientMode?: boolean;
  zoomSelfieHandler: () => void;
};

const SelfieComparison = ({
  originalAppImage,
  originalDbImage,
  cropAppImage,
  cropDbImage,
  selfieDetails,
  facePPDetails,
  zoom,
  debug,
  clientMode,
  zoomSelfieHandler,
}: Props) => {
  let decisionClass;

  if (selfieDetails.AIDecision === 'Match') {
    decisionClass = 'SelfieComparison-Green';
  } else if (selfieDetails.AIDecision === 'Mismatch')
    decisionClass = 'SelfieComparison-Red';
  else decisionClass = 'SelfieComparison-Black';

  let detailsDebug;
  let appImageDebug;
  let dbImageDebug;
  if (!clientMode) {
    detailsDebug = (
      <div className="SelfieComparison-Details">
        <div>
          <h5 className="SelfieComparison-Bold">Selfie Details</h5>
          <p className="SelfieComparison-NormalText">Model: Fraas</p>
          <p className="SelfieComparison-NormalText">
            Match Score:{' '}
            {!isFalsy(selfieDetails.matchScore)
              ? selfieDetails.matchScore.toFixed(2)
              : 'NA'}
          </p>
          <p className="SelfieComparison-NormalText">
            Threshold:{' '}
            {!isFalsy(selfieDetails.threshold) ? selfieDetails.threshold : 'NA'}
          </p>
          <p className="SelfieComparison-NormalText">
            AI Decision:{' '}
            {selfieDetails.AIDecision !== 'NA' ? (
              <span className={decisionClass}> {selfieDetails.AIDecision}</span>
            ) : (
              <span>{selfieDetails.AIDecision}</span>
            )}
          </p>
          <p className="SelfieComparison-NormalText">
            Distance:{' '}
            {!isFalsy(selfieDetails.distance)
              ? selfieDetails?.distance?.toFixed(4)
              : 'NA'}
          </p>
          <p className="SelfieComparison-NormalText">
            Threshold:{' '}
            {!isFalsy(selfieDetails.distanceThreshold)
              ? selfieDetails.distanceThreshold
              : 'NA'}
          </p>
        </div>
        <br />
        <div>
          <h5 className="SelfieComparison-Bold">Face++ Details</h5>
          <p className="SelfieComparison-NormalText">
            Selfie Blur: {getResultFromBoolean(facePPDetails?.selfieBlur)}
          </p>
          <p className="SelfieComparison-NormalText">
            Face quality:{' '}
            {!isFalsy(facePPDetails?.faceQuality)
              ? facePPDetails?.faceQuality.toFixed(2)
              : 'NA'}
          </p>
          <p className="SelfieComparison-NormalText">
            Threshold:{' '}
            {!isFalsy(facePPDetails?.threshold)
              ? facePPDetails?.threshold
              : 'NA'}
          </p>
          <p className="SelfieComparison-NormalText">
            IsImageRotated:{' '}
            {getResultFromBoolean(facePPDetails?.isImageRotated)}
          </p>
          <p className="SelfieComparison-NormalText">
            RotatedAngle:{' '}
            {!isFalsy(facePPDetails?.rotatedAngle)
              ? facePPDetails?.rotatedAngle
              : 'NA'}
          </p>
          <p className="SelfieComparison-NormalText">
            FacePPAngle:{' '}
            {!isFalsy(facePPDetails?.facePPAngle)
              ? facePPDetails?.facePPAngle.toFixed(2)
              : 'NA'}
          </p>
        </div>
      </div>
    );

    appImageDebug = (
      <div className="SelfieComparison-AppImage">
        <div>
          <Image
            src={originalAppImage}
            height={zoom ? 350 : 200}
            alt="original application image"
          />
        </div>
        <div>
          <Image src={cropAppImage} height={130} alt="crop application image" />
        </div>
        <p className="SelfieComparison-Tag">Query Image</p>
      </div>
    );
    dbImageDebug = (
      <div className="SelfieComparison-DBImage">
        <div>
          <Image
            src={originalDbImage}
            height={zoom ? 350 : 200}
            alt="original DB image"
          />
        </div>
        <div>
          <Image src={cropDbImage || ''} height={130} alt="crop DB image" />
        </div>
        <p className="SelfieComparison-Tag">Response Image</p>
      </div>
    );
  }

  const detailsQC = (
    <div className="SelfieComparison-Details">
      <h5 className="SelfieComparison-Bold">Details</h5>
      {clientMode ? null : (
        <p className="SelfieComparison-NormalText">Model: Fraas</p>
      )}
      <p className="SelfieComparison-NormalText">
        Match Score:{' '}
        {!isFalsy(selfieDetails.matchScore)
          ? selfieDetails.matchScore.toFixed(2)
          : 'NA'}
      </p>
      {clientMode ? null : (
        <p className="SelfieComparison-NormalText">
          Threshold:{' '}
          {!isFalsy(selfieDetails.threshold) ? selfieDetails.threshold : 'NA'}
        </p>
      )}
      <p className="SelfieComparison-NormalText">
        AI Decision:{' '}
        {selfieDetails.AIDecision !== 'NA' ? (
          <span className={decisionClass}> {selfieDetails.AIDecision}</span>
        ) : (
          <span>{selfieDetails.AIDecision}</span>
        )}
      </p>
    </div>
  );

  const appImageQC = (
    <div className="SelfieComparison-AppImage">
      <Image
        src={originalAppImage}
        height={zoom ? 350 : 200}
        alt="original application image"
      />
      <p className="SelfieComparison-Tag">Query Image</p>
    </div>
  );

  const dbImageQC = (
    <div className="SelfieComparison-DBImage">
      <Image
        src={originalDbImage}
        height={zoom ? 350 : 200}
        alt="original DB image"
      />
      <p className="SelfieComparison-Tag">Response Image</p>
    </div>
  );

  const comparisonRender = clientMode ? (
    <div className="SelfieComparison-Flex-Container">
      {appImageQC}
      {dbImageQC}
      {detailsQC}
    </div>
  ) : (
    <div className="SelfieComparison-Flex-Container">
      {debug ? appImageDebug : appImageQC}
      {debug ? dbImageDebug : dbImageQC}
      {debug ? detailsDebug : detailsQC}
    </div>
  );

  return (
    <div className="SelfieComparison-Container">
      <div className="SelfieComparison-Title-Holder">
        <p className="SelfieComparison-Title">Selfie Comparison</p>
        <div className="SelfieComparison-Icon">
          {zoom ? (
            <ZoomOutIcon onClick={() => zoomSelfieHandler()} />
          ) : (
            <ZoomInIcon onClick={() => zoomSelfieHandler()} />
          )}
        </div>
      </div>
      {comparisonRender}
    </div>
  );
};

export default SelfieComparison;
