import React from 'react';
import ZoomInIcon from '@material-ui/icons/ZoomIn';
import ZoomOutIcon from '@material-ui/icons/ZoomOut';
import Image from '../image/index';
import FaceDetails from './interface';
import { isFalsy } from '../../utils/validation';
import './idFaceComparison.css';

type Props = {
  originalAppIdImage: string;
  originalDbIdImage?: string;
  cropFaceAppImage: string;
  cropFaceDbImage: string;
  faceDetails: FaceDetails;
  zoom: boolean;
  debug?: boolean;
  clientMode?: boolean;
  zoomIdFaceHandler: () => void;
};

const IdFaceComparison = ({
  originalAppIdImage,
  originalDbIdImage,
  cropFaceAppImage,
  cropFaceDbImage,
  faceDetails,
  zoom,
  debug,
  clientMode,
  zoomIdFaceHandler,
}: Props) => {
  let decisionClass;

  if (faceDetails.AIDecision === 'Match') {
    decisionClass = 'IdFaceComparison-Green';
  } else if (faceDetails.AIDecision === 'Mismatch')
    decisionClass = 'IdFaceComparison-Red';
  else decisionClass = 'IdFaceComparison-Black';

  const detailsQC = (
    <div className="IdFaceComparison-Details">
      <br />
      <br />
      <h5 className="IdFaceComparison-Bold">Details</h5>
      {clientMode ? null : (
        <p className="SelfieComparison-NormalText">Model: Fraas</p>
      )}
      <p className="IdFaceComparison-NormalText">
        Match Score:{' '}
        {!isFalsy(faceDetails.matchScore)
          ? faceDetails.matchScore.toFixed(2)
          : 'NA'}
      </p>
      {clientMode ? null : (
        <p className="IdFaceComparison-NormalText">
          Threshold:
          {!isFalsy(faceDetails.threshold) ? faceDetails.threshold : 'NA'}
        </p>
      )}
      <p className="IdFaceComparison-NormalText">
        AI Decision:{' '}
        {faceDetails.AIDecision !== 'NA' ? (
          <span className={decisionClass}> {faceDetails.AIDecision}</span>
        ) : (
          <span>{faceDetails.AIDecision}</span>
        )}
      </p>
    </div>
  );

  const appImageQC = (
    <div className="IdFaceComparison-AppImage">
      <br />
      <br />

      <Image
        src={cropFaceAppImage}
        height={zoom ? 200 : 150}
        alt="original application image"
      />
      <p className="IdFaceComparison-Tag">Query Image</p>
    </div>
  );

  const dbImageQC = (
    <div className="IdFaceComparison-DBImage">
      <br />
      <br />
      <Image
        src={cropFaceDbImage}
        height={zoom ? 200 : 150}
        alt="original DB image"
      />
      <p className="IdFaceComparison-Tag">Response Image</p>
    </div>
  );

  let appImageDebug;
  let dbImageDebug;
  let detailsDebug;

  if (!clientMode) {
    detailsDebug = (
      <div className="IdFaceComparison-Details">
        <div>
          <h5 className="IdFaceComparison-Bold">Details</h5>
          <p className="SelfieComparison-NormalText">Model: Fraas</p>
          <p className="IdFaceComparison-NormalText">
            Match Score:{' '}
            {!isFalsy(faceDetails.matchScore)
              ? faceDetails.matchScore.toFixed(2)
              : 'NA'}
          </p>
          <p className="IdFaceComparison-NormalText">
            Threshold:
            {!isFalsy(faceDetails.threshold) ? faceDetails.threshold : 'NA'}
          </p>
          <p className="IdFaceComparison-NormalText">
            AI Decision:{' '}
            {faceDetails.AIDecision !== 'NA' ? (
              <span className={decisionClass}> {faceDetails.AIDecision}</span>
            ) : (
              <span>{faceDetails.AIDecision}</span>
            )}
          </p>
          <p className="IdFaceComparison-NormalText">
            Distance:
            {!isFalsy(faceDetails.distance)
              ? faceDetails?.distance?.toFixed(4)
              : 'NA'}
          </p>
          <p className="IdFaceComparison-NormalText">
            Threshold:{' '}
            {!isFalsy(faceDetails.distanceThreshold)
              ? faceDetails.distanceThreshold
              : 'NA'}
          </p>
        </div>
      </div>
    );

    dbImageDebug = (
      <div className="IdFaceComparison-DBImage">
        <div>
          <Image
            src={cropFaceDbImage}
            height={zoom ? 200 : 150}
            alt="original DB image"
          />
        </div>
        <div>
          <Image
            src={originalDbIdImage || ''}
            height={200}
            alt="crop DB image"
          />
        </div>
        <p className="IdFaceComparison-Tag">Response Image</p>
      </div>
    );

    appImageDebug = (
      <div className="IdFaceComparison-AppImage">
        <div>
          <Image
            src={cropFaceAppImage}
            height={zoom ? 200 : 150}
            alt="original application image"
          />
        </div>
        <div>
          <Image
            src={originalAppIdImage}
            height={200}
            alt="crop application image"
          />
        </div>
        <p className="IdFaceComparison-Tag">Query Image</p>
      </div>
    );
  }

  const comparisonRender = clientMode ? (
    <div className="IdFaceComparison-Flex-Container">
      {appImageQC}
      {dbImageQC}
      {detailsQC}
    </div>
  ) : (
    <div className="IdFaceComparison-Flex-Container">
      {debug ? appImageDebug : appImageQC}
      {debug ? dbImageDebug : dbImageQC}
      {debug ? detailsDebug : detailsQC}
    </div>
  );

  return (
    <div className="IdFaceComparison-Container">
      <div className="IdFaceComparison-Title-Holder">
        <p className="IdFaceComparison-Title">Id Face Comparison</p>
        <div className="IdFaceComparison-Icon">
          {zoom ? (
            <ZoomOutIcon onClick={() => zoomIdFaceHandler()} />
          ) : (
            <ZoomInIcon onClick={() => zoomIdFaceHandler()} />
          )}
        </div>
      </div>
      {comparisonRender}
    </div>
  );
};

export default IdFaceComparison;
