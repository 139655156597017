export const hitData = {
  reason: 'NA',
  name: 'NA',
  matchScore: {
    selfie: 0,
    idFaceCrop: 0,
  },
  aiDecision: {
    selfie: 'NA',
    idFace: 'NA',
    idNumber: 'NA',
  },
  dob: 'NA',
  nid: 'NA',
  idImages: {
    ssd: 'NA',
    fraasCrop: 'NA',
  },
  selfieImages: {
    original: 'NA',
  },
};

export const queryLogData = {
  images: {
    idImages: {
      ssd: 'NA',
      original: 'NA',
      fraasCrop: 'NA',
    },
    selfieImages: {
      original: 'NA',
      fraasCrop: 'NA',
    },
  },

  hits: {
    mismatches: [],
    ownDB: false,
    contributors: [],
    otherDB: false,
    matches: [],
  },

  count: {
    total: {
      matches: 0,
      mismatches: 0,
    },
  },
  input: {
    query: {
      idNumber: 'NA',
      name: 'NA',
      dob: 'NA',
    },
    ocr: {
      id: {
        value: 'NA',
        conf: 0,
      },
      name: {
        value: 'NA',
        conf: 0,
      },
      dob: {
        value: 'NA',
        conf: 0,
      },
    },
    user: {
      idNumber: 'NA',
      applicationId: 'NA',
      name: 'NA',
      dob: 'NA',
    },
  },
  check: {
    verifyPair: {
      match: false,
    },
    selfie: {
      quality: {
        blur: {
          overall: false,
        },
      },
    },
    verify: {
      value: false,
    },
    enrol: {
      value: false,
    },
    matchingAPI: {
      name: 'NA',
      idNumber: 'NA',
      dob: 'NA',
    },
  },
  requestId: 'NA',
  clientId: 'NA',
};
