const loginState = {
  accessToken: '',
  appId: '',
  appKey: '',
  isAuthenticated: false,
  error: false,
  errorMessage: '',
  loading: false,
  checkMade: false,
  role: '',
};

export default loginState;
